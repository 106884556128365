import { Injectable, inject, signal } from '@angular/core';
import { BaseService } from '../../../../../../shared/src/lib/services/base.service';
import {
  IAgentFraud,
  IAgentFraudSQ,
  IAgentMigration,
  IAgentMigrationPaidValidation,
  IAgentMigrationResp,
  IAgentMigrationValidation,
  IAgentRenewalStaged,
  IAgentRenewalStagedSQ,
  ICandidateRIA,
  ISaveAgentFraud,
  ISaveAgentLicense,
  ISaveAgentMigrationPaid,
  ISaveAgentMigrationStaging,
  ISaveLegacyRecord,
  ISaveTiedAgent,
  ITiedAgent,
  ITiedAgentSQ,
} from './agent.model';
import { ISR } from '../../../../../../shared/src/lib/models/index.model';
import { forkJoin, map, Observable, of } from 'rxjs';
import { IAgentStaging, IAgentStagingSQ } from './agent-staging.model';
import { environment } from '../../../../../../shared/src/environments/environment';
import {
  IUploadSubmissionResp,
  IValidationResp2,
} from '../../../../../../shared/src/lib/components/batch-uploads/batch-uploads-comps/batch-upload-input/batch-upload-input.model';
import { IBatchSubmissionFunction } from '../../../../../../shared/src/lib/components/batch-uploads/batch-uploads-comps/batch-upload-input/batch-upload-input.component';
import { EVFunctions, ISearchFormSchema, TableCol } from 'ets-fe-ng-sdk';
import { CodeFacadeService } from '../../set-ups/parameters/code.facade.service';
import { UtilityService } from '../../../../../../shared/src/lib/services/utility.service';
import { AsyncValidatorFn } from '@angular/forms';
import { AgentTypeFacadeService } from '../../../../../../shared/src/lib/facades/agent-type.facade.service';

@Injectable({
  providedIn: 'root',
})
export class AgentService extends BaseService {
  public codeF = inject(CodeFacadeService);
  public uS = inject(UtilityService);
  public agentFacade = inject(AgentTypeFacadeService);
  // public authS = inject(AuthenticationService);

  readonly tableColumns = signal<{ [k in keyof ITiedAgent]?: TableCol<ITiedAgent> }>({
    ria: { f: 'ria', t: 'RIA', routeFormatter: (r) => `../view?code=${r.ria}` },
    agentName: { f: 'agentName', t: 'Agent Name' },
    nin: { f: 'nin', t: 'NIN' },
    externalRef: { f: 'externalRef', t: 'External Ref.' },
    pin: { f: 'pin', t: ' ' },
    cifmCertNo: { f: 'cifmCertNo', t: ' ' },
    officePhoneNo: { f: 'officePhoneNo', t: 'Office Phone No.' },
    personalPhoneNo: { f: 'personalPhoneNo', t: 'Personal Phone No.' },
    officeEmail: { f: 'officeEmail', t: 'Office Email' },
    personalEmail: { f: 'personalEmail', t: 'Personal Email' },
    fraudInvestigation: {
      f: 'fraudInvestigation',
      t: 'Fraud Investigation',
      formatter: this.uS.yesNoCellFormatterColoured,
    },
    renewalDue: { f: 'renewalDue', t: 'Renewal Due', formatter: this.uS.yesNoCellFormatterColoured },
    renewalDueOn: { f: 'renewalDueOn', t: 'Renewal Due On', formatter: this.uS.fullDateTime },
    renewed: { f: 'renewed', t: 'Renewed', formatter: this.uS.yesNoCellFormatterColoured },
    notification: { f: 'notification', t: 'Notification', formatter: this.uS.yesNoCellFormatterColoured },
    renewalReminderStart: {
      f: 'renewalReminderStart',
      t: 'Renewal Reminder Start',
      formatter: this.uS.fullDateTime,
    },
    lastRenewalReminder: {
      f: 'lastRenewalReminder',
      t: 'Last Renewal Reminder',
      formatter: this.uS.fullDateTime,
    },
    tin: { f: 'tin', t: 'Tin' },
    employedOn: { f: 'employedOn', t: 'Employed On', formatter: this.uS.fullDateTime },
    batchNo: { f: 'batchNo', t: 'Batch No.' },
    batch: { f: 'batch', t: 'Batch', formatter: this.uS.yesNoCellFormatterColoured },
    // agentStagingId: { f: 'agentStagingId', t: 'Agent Staging Id' },
    activated: { f: 'activated', t: 'Activated', formatter: this.uS.yesNoCellFormatterColoured },
    legacyRia: { f: 'legacyRia', t: 'Legacy RIA.' },
    status: {
      f: 'status',
      t: 'Status',
      formatter: this.codeF.fetchTitleByCodeAndSubgroupFactory('AGENT_STATUS'),
    },
  });

  readonly stagingColumns = signal<TableCol<IAgentStaging>[]>([
    { f: 'agentName', t: 'Agent Name' },
    { f: 'agentType', t: 'Agent Type', formatter: this.agentFacade.fetchDescriptionByCode },
    { f: 'pin', t: ' ' },
    { f: 'cifmCertNo', t: ' ' },
    { f: 'nin', t: 'NIN' },
    { f: 'tin', t: 'TIN' },
    { f: 'officeEmail', t: 'Office Email' },
    { f: 'officePhoneNo', t: 'Office Phone No.' },
    { f: 'personalEmail', t: 'Personal Email' },
    { f: 'personalPhoneNo', t: 'Personal Phone No.' },
    { f: 'employedOn', t: 'Employed On', formatter: this.uS.fullDateTime },
    // { f: 'reason', t: 'Reason' },
    // { f: 'externalRef', t: 'External Ref.' },
    // { f: 'employerCode', t: 'Employer Code', formatter: this.companyFacade.fetchDescriptionByCode },
    // { f: 'createdOn', t: 'Created On', formatter: this.uS.fullDateTime },
    // { f: 'createdBy', t: 'Created By' },
  ]);
  readonly riaSearchFormField = <T extends { ria?: string }>() =>
    <ISearchFormSchema<T>>{
      field: 'ria',
      label: 'RIA',
      standalone: true,
      asyncValidators: [this.asyncValidateRIA],
    };
  protected override baseURL = `v1/agent/`;

  searchTiedAgents = (query: ITiedAgentSQ) => this.get<ISR<ITiedAgent>>('search', query);

  activateAgents = (ids: number[]) => this.post(ids, 'batch/activate');

  getTiedAgentByRIA = (ria: string): Observable<ITiedAgent | null> =>
    ria ? this.searchTiedAgents({ ria }).pipe(map((r) => r.content?.[0])) : of(null);
  getTiedAgentByCandidateNo = (candidateNo: string) =>
    this.searchTiedAgents({ candidateNo }).pipe(map((r) => r.content?.[0]));
  getTiedAgentByPersonalEmail = (personalEmail: string) =>
    this.searchTiedAgents({ personalEmail }).pipe(map((r) => r.content?.[0]));
  getStagedAgentByPersonalEmail = (personalEmail: string) =>
    this.searchAgentStaging({ personalEmail }).pipe(map((r) => r.content?.[0]));
  asyncValidateRIA: AsyncValidatorFn = (control) => {
    const val = String(control?.value)
      ?.trim()
      ?.toLowerCase();
    if (val)
      return this.searchTiedAgents({ ria: val }).pipe(
        map((sr) =>
          sr?.content?.some((x) => x.ria.toLowerCase() == val) ? null : { custom: `Invalid RIA` },
        ),
      );
    return of(null);
  };

  saveTiedAgent(data: ISaveTiedAgent) {
    return this.post<ITiedAgent>(data, ``);
  }

  saveLegacyRecord(data: ISaveLegacyRecord) {
    return this.post<ITiedAgent>(data, `legacy/record`);
  }

  updateAgent = (data: ITiedAgent) => {
    return this.put(data, data.id + '');
  };

  protected agentMigration = (data: IAgentMigration[]) => {
    return this.post<IAgentMigrationResp[]>({ migrateAgentRequestList: data }, `migrate`);
  };

  candidateRIAMigration = (data: ICandidateRIA[]) => {
    return this.apiS.put<
      {
        responseMessage: string;
        responseStatus: string;
        rowId: string;
      }[]
    >(`v1/cifm/ria`, { candidateRIAUpdateRequestList: data });
  };

  agentMigrationValidation = (data: IAgentMigrationValidation[]) => {
    return this.post<IAgentMigrationValidation[]>(
      data.map((x) => ({
        ...x,
        rowId: x.__rowID,
      })),
      `bulk/validate`,
    ).pipe(
      map<IAgentMigrationValidation[], IValidationResp2<IAgentMigrationValidation>>((r) => ({
        rowIDField: 'rowId',
        data: r,
      })),
    );
  };

  agentMigrationPaidValidation = (data: IAgentMigrationPaidValidation[]) => {
    return this.agentMigrationValidation(
      data.map((x) => ({
        ...x,
        personalEmail: x['agentEmail'],
        personalPhoneNo: x['agentPhoneNo'],
      })),
    ).pipe(
      map(
        (r) =>
          <IValidationResp2<IAgentMigrationPaidValidation>>{
            rowIDField: r.rowIDField,
            flattenObj: r.flattenObj,
            data: r.data?.map(({ personalEmail, personalPhoneNo, ...x }) => ({
              ...x,
              agentEmail: personalEmail,
              agentPhoneNo: personalPhoneNo,
            })),
          },
      ),
    );
  };

  protected agentMigrationStaging(data: ISaveAgentMigrationStaging[]) {
    data.forEach(
      (x) => (x.agentName = x.agentName || EVFunctions.strConcatenator2(x.firstName, x.lastName, ' ')),
    );
    return this.post<[]>({ migrateAgentStagingList: data }, `migrate/staging`);
  }

  protected uploadStagingFunc: IBatchSubmissionFunction = (arr: ISaveAgentMigrationStaging[]) => {
    try {
      return this.agentMigrationStaging(arr).pipe(
        map(
          (r) =>
            <IUploadSubmissionResp>{
              reportArray: r,
            },
        ),
      );
    } catch (error) {
      throw error;
    }
  };

  protected uploadStagingPaid = (payload: ISaveAgentMigrationPaid) =>
    this.post(payload, 'legacy/license').pipe(
      map(
        (r) =>
          <IUploadSubmissionResp>{
            reportArray: r,
          },
      ),
    );

  saveAgentLicense(data: ISaveAgentLicense) {
    return this.post<ITiedAgent[]>(data, `license`);
  }

  saveAgentFraud(data: ISaveAgentFraud) {
    return this.post(data, `fraud`);
  }

  updateAgentFraud(data: ISaveAgentFraud, id: number) {
    return this.put(data, `fraud/` + id);
  }

  searchAgentFraud = (query: IAgentFraudSQ) => this.get<ISR<IAgentFraud>>('fraud/search', query);

  saveAgentStaging(data: IAgentStaging[]) {
    return this.post<IAgentStaging[]>(data, `staging`);
  }

  searchAgentStaging = (query: IAgentStagingSQ) => this.get<ISR<IAgentStaging>>('staging/search', query);

  deleteAgentStaging = (ids: number[]) => forkJoin(ids.map((id) => this.delete('staging/' + id)));

  saveAgentRenewalStaging(data: IAgentRenewalStaged) {
    return data.id
      ? this.put<IAgentStaging>(data, `renewal/staging/${data.id}`)
      : this.post<IAgentStaging>(data, `renewal/staging`);
  }

  batchRenewal(
    data: {
      rowId: number;
      employer: string;
      ria: string;
    }[],
  ) {
    return this.post<IAgentStaging>(data, `renew`);
  }

  searchAgentRenewalStaging = (query: IAgentRenewalStagedSQ) =>
    this.get<ISR<IAgentRenewalStaged>>('renewal/staging', query).pipe(
      map((r) => {
        if (!environment.production && !r.content?.length) r.content.push({ tin: '2222', ria: 'www' } as any);

        return r;
      }),
    );

  deleteAgentRenewalStaging = (ids: number[]) =>
    forkJoin(ids.map((id) => this.delete('renewal/staging/' + id)));

  searchCompanyAgents = (query: { dateFrom?: string; dateTo?: string }) =>
    this.get<ICompanyAgent[]>('company/find', {
      dateFrom: query.dateFrom ? query.dateFrom + ' 00:00:00' : query.dateFrom,
      dateTo: query.dateTo ? query.dateTo + ' 00:00:00' : query.dateTo,
    });
}
interface ICompanyAgent {
  agentName: string;
  createdOn: string;
  externalRef: string;
  ria: string;
}
